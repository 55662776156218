import App from '@shoreag/base/App';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import SnackbarProvider from 'react-simple-snackbar';
import { Auth } from 'aws-amplify';
import { AuthContext } from '@shoreag/auth';
import { Helmet } from 'react-helmet';
import { UUID_PREFIXES } from '@shoreag/helpers/constants';
import { useIdleTimer } from 'react-idle-timer';
import config from '../../config.json';
import theme from '../../theme';
import { USER_LAST_ACTIVE, idleTimeOut } from '../../utilities/constants';
import removeTrailingSlash from '../../utilities/remove-trailing-slash';
import getPublicPaths from '../../utilities/get-public-paths';

const Layout = ({ children, location: { pathname } }) => {
  const [userId, setUserId] = useState(null);
  useEffect(() => ReactGA.initialize(config.googleAnalytics), []);
  useEffect(() => ReactGA.set({ userId }), [userId]);
  useEffect(() => ReactGA.pageview(pathname), [pathname]);

  const isPublicPath =
    typeof window !== 'undefined' &&
    pathname &&
    getPublicPaths().includes(removeTrailingSlash(pathname));

  useIdleTimer({
    crossTab: {
      emitOnAllTabs: true,
    },
    onAction: async () => {
      if (!isPublicPath) {
        const lastActiveTime = localStorage.getItem(USER_LAST_ACTIVE);
        try {
          const user = await Auth.currentSession();
          if (
            !user ||
            (lastActiveTime &&
              new Date().getTime() - new Date(lastActiveTime).getTime() >=
                idleTimeOut - 1000)
          ) {
            await Auth.signOut();
            localStorage.removeItem(USER_LAST_ACTIVE);
          } else {
            localStorage.setItem(USER_LAST_ACTIVE, new Date());
          }
        } catch (e) {
          await Auth.signOut();
          localStorage.removeItem(USER_LAST_ACTIVE); // will signout from the portal if faced any issue getting the current session.
        }
      }
    },
    onIdle: async () => {
      if (!isPublicPath) {
        await Auth.signOut();
        localStorage.removeItem(USER_LAST_ACTIVE);
      }
    },
    timeout: idleTimeOut,
  });

  return (
    <App config={config} credentials={Auth.currentCredentials} theme={theme}>
      <Helmet>
        <link
          href={`/${config.favicon}.ico`}
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
      </Helmet>
      <AuthContext.Consumer>
        {({ user }) => {
          if (user.sub) {
            setUserId(UUID_PREFIXES.USER + user.sub);
          }
        }}
      </AuthContext.Consumer>
      <SnackbarProvider>{children}</SnackbarProvider>
    </App>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default Layout;

import { lighten, darken, linearGradient } from 'polished';
import defaultTheme from './default';

const { colors, gradients } = defaultTheme;

const shoreGradients = {
  ...gradients,
  sidebar: linearGradient({
    colorStops: [
      `${lighten(0.1, colors.primary)} 0%`,
      `${darken(0.1, colors.primary)} 100%`,
    ],
    fallback: colors.primary,
    toDirection: 'to bottom',
  }),
};

const shoreTheme = {
  ...defaultTheme,
  gradients: shoreGradients,
};
export default shoreTheme;
